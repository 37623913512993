.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* General Styles */
$font-faces: (
  Roboto-bold: "./styles/fonts/Roboto-Bold.ttf",
  Roboto-italic: "./styles/fonts/Roboto-Italic.ttf",
  Roboto-regular: "./styles/fonts/Roboto-Regular.ttf",
  Roboto-light: "./styles/fonts/Roboto-Light.ttf",
  Roboto-thinitalic: "./styles/fonts/Roboto-ThinItalic.ttf",
  Roboto-thin: "./styles/fonts/Roboto-Thin.ttf"
);

@each $font-name,
$font-url in $font-faces {
  @font-face {
    font-family: #{$font-name};
    src: url($font-url);
  }
}


body {
  font-family: Roboto-regular;

  Link {
    text-decoration: none;
  }
}

.tbodyy {
  size: 1vw !important;

  label {
    text-decoration: none;
    font-weight: 450;
    text-transform: none !important;
  }
}

footer {
  background-color: #eeeeee;

  .footercito {
    background-color: white;
  }
}

.container-fluid {
  background-color: #eeeeee;
}

/* Footer */

footer {
  font-family: Roboto-regular;
  color: black;
  text-decoration: none;
  font-size: 1h;

  .footer-left {
    .footer-yellow {
      background-color: #ffd102;
    }

    .footer-red {
      background-color: #bd2a31;
    }

  }

  .colorcito {
    font-family: Roboto-italic;
    color: black;
  }

  .footer-images {
    width: 90%;
    height: auto;
    padding-bottom: 10px;
  }

  .footer-logo {
    width: 100%;
    height: 100%;
  }

  .footer-last {
    text-align: right;
    font-family: Roboto-thinitalic;
    color: white;
    text-align: right;

    .text-footer {
      font-family: Roboto-italic;
    }
  }

  .Navbar {
    background-color: white;
  }
}

.form-check-input {
  width: auto !important;
  height: auto !important;
}

/*
Central
*/
.carouselIMG {
  height: 80vh;
}

@import url(https://fonts.googleapis.com/css?family=Lato:900);

*,
*:before,
*:after {
  box-sizing: border-box;
}

div.foo {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.letter {
  display: inline-block;
  font-weight: 900;
  font-size: 5em;
  margin: 0.1em;
  position: relative;
  color: #bd2a31;
  transform-style: preserve-3d;
  perspective: 400;
  z-index: 1;
}

.letter:before,
.letter:after {
  position: absolute;
  content: attr(data-letter);
  transform-origin: top left;
  top: 0;
  left: 0;
}

.letter,
.letter:before,
.letter:after {
  transition: all 0.3s ease-in-out;
}

.letter:before {
  color: #d8cfcf;
  text-shadow:
    -1px 0px 1px rgba(255, 255, 255, .8),
    1px 0px 1px rgba(0, 0, 0, .8);
  z-index: 3;
  transform:
    rotateX(0deg) rotateY(-15deg) rotateZ(0deg);
}

.letter:after {
  color: rgba(0, 0, 0, .11);
  z-index: 2;
  transform:
    scale(1.08, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 1deg);
}

.letter:hover:before {
  color: #ffd102;
  transform:
    rotateX(0deg) rotateY(-40deg) rotateZ(0deg);
}

.letter:hover:after {
  transform:
    scale(1.08, 1) rotateX(0deg) rotateY(40deg) rotateZ(0deg) skew(0deg, 22deg);
}

.directionNav {
  text-align: right;
}

.hover-Bar1 {
  font-family: Roboto-italic;
  text-decoration: none;
  margin-left: 1%;
  margin-right: 1%;
  color: #282c34;

  &.active {
    color: #000;
    font-family: Roboto-bold;
  }

  &:hover {
    text-decoration: underline;
    padding-left: 1%;
    padding-top: 1%;
    padding-right: 1%;
    transition-property: background-color, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
  }
}

.hover-fold {
  background: #e8c63d;
  border: 5px white solid;
  height: 100vh;
  margin: 10px auto;
  -moz-perspective: 1500;
  -webkit-perspective: 1500;
  perspective: 1500;
  position: relative;
  width: 100%;
}

.hover-fold h2 {
  border-bottom: 1px solid #333;
  margin: 0 40px;
  padding: 110px 0 2px;
}

.hover-fold .top {
  height: 50%;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 10;
  text-align: justify;
}

.hover-fold .top .face {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.hover-fold:hover .top {
  -moz-transform: rotateX(-180deg);
  -ms-transform: rotateX(-180deg);
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.hover-fold .front {
  background: url('./images/mantener-el-certificado-ISO-9001.jpg');
  height: 100%;
  /*has to be 100% of .top */
  width: 100%;
  background-repeat: no-repeat;
}

.hover-fold .back {
  background: #e8c63d;
  height: 100%;
  /*has to be 100% of .top */
  padding: 0 40px;
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  width: 100%;
}

.hover-fold .back p {
  margin: 0;
}

.hover-fold .bottom {
  background: url('./images/iso.jpg') bottom;
  height: 50%;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;
  background-repeat: no-repeat;
}

/*
Invetario
*/



/*
Flotilla
*/
/* Inicio */
@media (max-width: 768px) {
  .card-title {
    font-size: 1rem; /* Tamaño más pequeño para pantallas estrechas */
  }
}
.card-hover:hover .card-titles {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.5); /* Ajusta la opacidad según sea necesario */
  border-radius: 5px; /* Opcional, para suavizar las esquinas */
}

.card-title {
  font-family: 'Arial', sans-serif; /* O una tipografía de tu elección */

}

.card-hover::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, transparent 70%);
  transform: scale(0);
  opacity: 0;
  transition: transform 0.5s, opacity 0.3s;
}

.card-hover:hover::after {
  transform: scale(1);
  opacity: 1;
}



/*Otros*/
.brand {
  display: flex;
  align-items: center;
}

.brand-image {
  width: auto;
  height: 10vh;
}

/* Report_flotilla */
.imagen-personalizada {
  width: 100% !important;
  height: 50vh !important;
}

.card-reporte {
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s !important;
  /* Agregamos transición para box-shadow */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0) !important;
  /* Inicialmente, sin sombra */
}

.card-reporte:hover {
  background-color: #ccc !important;
  color: #000 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5) !important;
  /* Agregamos una sombra al hacer hover */
}

/* Index flotilla */
.card-hover:hover {
  transform: scale(1.03); /* Aumenta ligeramente la escala de la tarjeta */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Suaviza la transición */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Aumenta la sombra alrededor de la tarjeta */
}

/* Para asegurarse de que la transición sea suave al dejar de hacer hover también */
.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* TableUsers.css */
.text-decoration-none:hover {
  text-decoration: none; /* Asegurarse de que no hay subrayado al pasar el ratón por encima */
}



/* Nav bar */
.container-fluid,
.container {
  position: relative;

  .super-Bar {
    &:hover {
      .hover-content {
        display: block;
        z-index: 3;
      }
    }
  }

  .hover-Bar {
    font-family: Roboto-italic;
    text-decoration: none;
    margin-left: 1%;
    margin-right: 1%;
    color: #282c34;

    &.active {
      color: #000;
      font-family: Roboto-bold;
    }

    &:hover {
      text-decoration: underline;
      background-color: white;
      padding: 1%;
      transition-property: background-color, transform;
      transition-timing-function: ease-in-out;
      transition-duration: 500ms;
    }
  }

  .hover-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 13vw;
    width: 580%;
    background-color: rgb(244, 242, 242);
    z-index: 1;
  }
}

.super-Bar:hover {
  text-decoration: line-through;
}

.third-Bar {
  position: absolute;
  background-color: white;
  color: black;
  z-index: 2;
  padding: 10px 10px 10px 10px;
}

.bar-Subcategory {
  text-decoration: none;
  color: black;
  font-family: Roboto-thinitalic;
}

.chatin {
  position: absolute;
  width: auto;
  height: 40%;
  transform: scaleX(-1);
}

.prcie {
  text-decoration: none;
  color: #000;

  &:hover {
    font-family: Roboto-bold;
  }
}

.button-images {
  height: 33vh;
  width: auto;
}

.files-upload {
  text-align: center;
}

.custom-file-upload {
  position: relative;
  display: inline-block;
}

.image-container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Color semi-transparente */
  opacity: 0;
  /* Inicialmente invisible */
  transition: opacity 0.3s ease;
  /* Efecto de transición */
}

/* Efecto hover */
.custom-file-upload:hover .overlay {
  opacity: 1;
  /* Hace que la capa se vuelva visible en el hover */
}

.textarea-like {
  border: 1px solid #ccc;
  /* Añade un borde para simular un textarea */
  padding: 5px;
  /* Espaciado interno para separar el texto del borde */
  min-height: 12vh;
  /* Altura mínima para asegurar visibilidad */
  overflow-y: auto;
  /* Añade una barra de desplazamiento vertical si el contenido es largo */
}

label {
  text-transform: uppercase;
  font-weight: bold;
}

/* Reporte Mantenimiento */

.signature-canvas {
  width: 80%;
  height: 25vw;
  border: 1px solid #000;
}

.vo {
  background: rgb(166, 221, 184);
  background: linear-gradient(0deg, rgba(166, 221, 184, 1) 0%, rgba(187, 241, 187, 1) 6%, rgba(175, 237, 167, 0.25654768743434875) 100%);
}

.tbodyy2 {
  background-image: url('./images/car.jpg') !important;
  background-size: cover !important; /* O la propiedad que desees */
  background-repeat: no-repeat !important;
  background-position: center !important; /* O la propiedad que desees */
}

/* Documents */

.pdf-viewer {
  width: 100%; // Ancho completo para ocupar el contenedor
  height: 100vh; // Altura fija, ajustable según tus necesidades
  border: 1px solid #dee2e6; // Un borde ligero y elegante
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Sombra sutil para dar profundidad
  border-radius: 10px; // Bordes redondeados para una apariencia moderna
  overflow: hidden; // Ocultar el desbordamiento para mantener los bordes redondeados

  // Media query para responsividad
  @media (max-width: 768px) {
      height: 300px; // Altura más pequeña para dispositivos móviles
  }
}
