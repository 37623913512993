body {
    color: #000 !important;
    overflow-x: hidden !important;
    height: 100% !important;
    background-color: #B0BEC5 !important;
    background-repeat: no-repeat !important;
}

.card0 {
    box-shadow: 0px 4px 8px 0px #757575 !important;
    border-radius: 0px !important;
}

.card2 {
    margin: 0px 40px !important;
}

.logo {
    width: 200px !important;
    height: 100px !important;
    margin-top: 20px !important;
    margin-left: 35px !important;
}

.image {
    width: 360px !important;
    height: 280px !important;
}

.border-line {
    border-right: 1px solid #EEEEEE!important;
}

.facebook {
    background-color: #3b5998 !important;
    color: #fff !important;
    font-size: 18px !important;
    padding-top: 5px !important;
    border-radius: 50% !important;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer !important;
}

.twitter {
    background-color: #1DA1F2 !important;
    color: #fff !important;
    font-size: 18px !important;
    padding-top: 5px !important;
    border-radius: 50% !important;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer !important;
}

.linkedin {
    background-color: #2867B2 !important;
    color: #fff !important;
    font-size: 18px !important;
    padding-top: 5px !important;
    border-radius: 50% !important;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer !important;
}

.line {
    height: 1px !important;
    width: 35% !important;
    background-color: #E0E0E0 !important;
    margin-top: 10px !important;
}

.or {
    width: 30% !important;
    font-weight: bold !important;
}

.text-sm {
    font-size: 14px !important;
}

::placeholder {
    color: #BDBDBD !important;
    opacity: 1 !important;
    font-weight: 300 !important;
}

:-ms-input-placeholder {
    color: #BDBDBD !important;
    font-weight: 300 !important;
}

::-ms-input-placeholder {
    color: #BDBDBD !important;
    font-weight: 300 !important;
}

input, textarea {
    padding: 10px 12px 10px 12px !important;
    border: 1px solid lightgrey !important;
    border-radius: 2px !important;
    margin-bottom: 5px !important;
    margin-top: 2px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    color: #2C3E50 !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
}

input:focus, textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #304FFE !important;
    outline-width: 0 !important;
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0 !important;
}

a {
    color: inherit !important;
    cursor: pointer !important;
}

.btn-blue {
    background-color: #1A237E !important;
    width: 150px !important;
    color: #fff !important;
    border-radius: 2px !important;
}

.btn-blue:hover {
    background-color: #000 !important;
    cursor: pointer !important;
}

.bg-blue {
    color: #fff !important;
    background-color: #1A237E !important;
}

@media screen and (max-width: 991px) {
    .logo {
        margin-left: 0px !important;
    }

    .image {
        width: 300px !important;
        height: 220px !important;
    }

    .border-line {
        border-right: none !important;
    }

    .card2 {
        border-top: 1px solid #EEEEEE !important;
        margin: 0px 15px !important;
    }
}