.animation-container {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .animation-container.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animation-img {
    width: 200px;
    height: auto;
  }
  
  .animation-text {
    font-size: 18px;
    color: #333;
  }
  